import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Alert, Modal } from "antd";

import Index from "@/pages/base/indexnew";

import FeedBack from "./pages/feedback";
import Alerts from "./pages/alerts";

import "./App.css";
import "./apps.less";

function App(porps) {
  return (
    <div className="app" style={{ position: "relative", height: "100%" }}>
      <Alerts />

      <div className="feedback-box">
        <FeedBack />
      </div>

      <Switch>
        {/* 首页部分 */}
        {/* <Route path="/" exact component={Index} onEnter={() => { document.title = "登录 - 速聘达" }} /> */}
        <Route path="/index" component={Index} />

        {/* <Route path="/test/upload" component={PicturesWall} /> */}
        <Redirect to="/index" />
      </Switch>
    </div>
  );
}

export default App;
