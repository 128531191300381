import React from "react";
import "./index.less";
import { Row, Col } from "antd";

export default class footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: this.props.style,
    };
  }
  render() {
    const style = this.state.style;
    return (
      <footer>
        <div className="px-1 text-center">
          <div className="link-list my-2">
            <a href="https://slzhitui.com/">首页</a>
            <span>|</span>
            <a href="https://slzhitui.com/selected">人才精选</a>
            <span>|</span>
            <a href="https://slzhitui.com/hot">热招职位</a>
            <span>|</span>
            <a href="https://slzhitui.com/famous">名企专区</a>
            <span>|</span>
            <a href="https://slzhitui.com/service">服务简介</a>
          </div>
          <div className="logo">
            <img
              src={require("../atess/image/logo.png")}
              style={{ height: "30px" }}
            />
          </div>
          <div className="copyright mb-3">
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              style={{ color: "#333" }}
            >
              沪ICP备11002961号-1
            </a>
          </div>
          <div
            className="d-flex text-center mb-3"
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="mr-3">
              <img
                src={require("@/atess/image/gongzhonghao.jpg")}
                style={{ width: "100px" }}
              />
              <div className="mt-1" style={{ color: "#333" }}>
                官方公众号
              </div>
            </div>
            <div className="">
              <img
                src={require("@/atess/image/qrcode.png")}
                style={{ width: "100px" }}
              />
              <div className="mt-1" style={{ color: "#333" }}>
                微信客服
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
