import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import { Layout, Menu, Button, Dropdown, message, Alert } from "antd";
import cookie from "react-cookies";
import { DownOutlined } from "@ant-design/icons";
import httpPromise from "@/api";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const Headers = (props) => {
  const { userinfo } = props;

  const selectDownMenu = ({ key }) => {
    switch (key) {
      case "logout":
        // this.setState({ loading: true });
        httpPromise({ apiDemo: "companyLoginOut" })
          .then((res) => {
            // this.setState({ loading: false });
            if (res.code === 200) {
              message.success("退出成功！");
              cookie.remove("access_token", { path: "/" });
              localStorage.removeItem("userInfo");
              window.location.reload();
            } else {
              message.error(res.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ position: "sticky", top: 0, zIndex: 99, width: "100%" }}>
      <Header className="top-header" theme="light">
        <div className="container">
          <Link className="logo" to="/index">
            <img src={require("@/atess/image/logo-lan.png")} />
          </Link>

          <div className="btn-box right">
            <Button
              type="primary"
              size="small"
              href="https://slzhitui.com/entrance/biz/login"
            >
              登录
            </Button>
            <Button
              type="primary"
              size="small"
              href="https://slzhitui.com/entrance/biz/register"
            >
              注册
            </Button>
            {/* <Button type="default" ghost href="https://hr.slzhitui.com/login">
                前往HR版
              </Button> */}
          </div>
        </div>
      </Header>
    </div>
  );
};

export default Headers;
