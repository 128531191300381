import React, { useState } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import {
  Spin,
  Layout,
  Row,
  Col,
  Menu,
  Dropdown,
  Avatar,
  message,
  Input,
  Button,
  Carousel,
  Icon,
  Card,
  Affix,
  Alert,
  AutoComplete,
  Tabs,
} from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";

import { UserOutlined, SearchOutlined } from "@ant-design/icons";
import httpPromise from "@/api";

import WOW from "wowjs";
import ReactWOW from "react-wow";
import Slider from "@ant-design/react-slick";
import cookie from "react-cookies";

import "./indexnew.less";
import "animate.css";

import CompanyLogin from "../entrance/login/companylogin";
import Headers from "./Headers";
import HomeFooter from "../../components/homeFooter";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

class Indexnew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userinfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      mode: "inline",
      theme: "light",
      cur: "companyGetSearchJob",
      list: [],
    };
    this.ref = null;
    this.carouselRef = React.createRef();
  }
  componentDidMount() {
    this.getUserInfo();
    this.getSearch();
  }
  componentWillUnmount() {}

  getUserInfo = async () => {
    let t = this;
    httpPromise({ apiDemo: "companyGetUserInfo" })
      .then(function (res) {
        if (res.code === 200) {
          t.setState({ loading: false, userinfo: res.data });
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          cookie.save("userinfo", res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSearch = async () => {
    let t = this;
    this.setState({ loading: true });
    httpPromise({
      apiDemo: this.state.cur,
      params: {
        pageNo: 1,
        pageSize: 8,
        total: 0,
      },
    })
      .then(function (res) {
        console.log(res);
        if (res.code === 200) {
          t.setState({
            list: [...res.data.data],
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { userinfo, loading, cur, list } = this.state;

    return (
      <Spin spinning={false}>
        <Headers userinfo={userinfo} />
        <Layout className="indexnew">
          <Layout style={{ minHeight: "100vh" }}>
            <Content style={{ position: "relative" }}>
              <div className="top-banner">
                <div className="container">
                  <div className="title">企业招聘就上速猎直推</div>
                  <div className="subtitle">
                    <img src={require("../../atess/image/top.png")} />
                    <span>悬赏招聘开创者</span>
                  </div>
                </div>
              </div>

              {/* <div className="intro-info mt-1">
                <section className="container px-1">
                  <dl>
                    <dt>
                      <span>专业</span>
                    </dt>
                    <dd>活跃精英人才库</dd>
                  </dl>
                  <dl>
                    <dt>
                      <span>沟通</span>
                    </dt>
                    <dd>极速发布职位 搜索查看简历</dd>
                  </dl>
                  <dl>
                    <dt>
                      <span>高效</span>
                    </dt>
                    <dd>大数据算法智能推荐匹配人选</dd>
                  </dl>
                  <dl>
                    <dt>
                      <span>交易</span>
                    </dt>
                    <dd>优质企业猎头订单</dd>
                  </dl>
                </section>
              </div> */}

              <div className="top-imgs mt-1 px-1">
                <div className="small">
                  <div className="item">
                    <img src={require("../../atess/image/img1.png")} />
                    <span>海量人才智能推荐</span>
                  </div>
                  <div className="item">
                    <img src={require("../../atess/image/img3.png")} />
                    <span>精准匹配多对一</span>
                  </div>
                </div>
                <div className="small">
                  <div className="item">
                    <img src={require("../../atess/image/img2.png")} />
                    <span>火速入职</span>
                  </div>
                  <div className="item">
                    <img src={require("../../atess/image/img4.png")} />
                    <span>少花钱 多招人</span>
                  </div>
                </div>
              </div>
              <div className="nav-list mt-1 px-1">
                <Tabs
                  defaultActiveKey="send"
                  items={[
                    {
                      key: "companyGetSearchJob",
                      label: `热门职位`,
                      children: null,
                    },
                    {
                      key: "companyGetSearch",
                      label: `精选简历`,
                      children: null,
                    },
                    {
                      key: "companyGetEnterpriseSearchJob",
                      label: `名企招聘`,
                      children: null,
                    },
                  ]}
                  onChange={(key) => {
                    console.log(key);
                    this.setState(
                      {
                        cur: key,
                      },
                      () => {
                        this.getSearch();
                      }
                    );
                  }}
                />

                {(!loading && (
                  <div className="loading">
                    {(cur == "companyGetSearchJob" && (
                      <div className="search-list">
                        {list.map((item, index) => (
                          <div
                            className={`search-list-item`}
                            key={index}
                            onClick={() => {
                              window.location.href =
                                "https://slzhitui.com/biz/home";
                            }}
                          >
                            <div className="head">
                              <div className="name">
                                <div className="title">{item.title}</div>
                                <div className="subtitle">
                                  [{item.occupation_name}]
                                </div>
                              </div>
                              <div className="price">
                                {(item.min_salary_name ===
                                  item.max_salary_name &&
                                  item.max_salary_name) ||
                                  `${item.min_salary_name} ~ ${item.max_salary_name}`}
                              </div>
                            </div>
                            <div className="cont">
                              <div className="tag">{item.education_name}</div>
                              <div className="tag">{item.experience_name}</div>
                              <div className="tag">
                                {(item.sex == 0 && "不限") ||
                                  (item.sex == 1 && "男") ||
                                  (item.sex == 2 && "女") ||
                                  ""}
                              </div>
                              <div className="tag">
                                {(item.min_age === item.max_age &&
                                  item.max_age) ||
                                  `${item.min_age} ~ ${item.max_age}`}
                              </div>
                              {/* <div className="tag">{item.state}</div> */}
                            </div>
                            <div className="foot">
                              <div className="postname">
                                <img
                                  className="cover"
                                  src={require(`../../atess/image/user-cover.png`)}
                                  style={{ width: "20px", marginRight: "8px" }}
                                />
                                {item.interview_contact}
                              </div>
                              <div className="address">
                                {item.work_provinces_name}-
                                {item.work_cities_name}-{item.work_areas_name}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )) ||
                      (cur == "companyGetSearch" && (
                        <div className="search-list">
                          {list.map((item, index) => (
                            <div
                              className="search-list-item"
                              key={index}
                              onClick={() => {
                                window.location.href =
                                  "https://slzhitui.com/biz/home";
                              }}
                            >
                              <div className="head">
                                <div className="name">
                                  <img
                                    className="cover"
                                    src={require(`../../atess/image/indexnew/${
                                      item.sex == "男" ? "man" : "woman"
                                    }.png`)}
                                  />
                                  <span>{item.name}</span>
                                </div>
                                <div className="price">
                                  {(item.min_salary === item.max_salary &&
                                    item.max_salary) ||
                                    `${item.min_salary} ~ ${item.max_salary}`}
                                </div>
                              </div>
                              <div className="cont">
                                <div className="tag">{item.education}</div>
                                <div className="tag">{item.experience}</div>
                                <div className="tag">{item.sex}</div>
                                <div className="tag">{item.age}</div>
                                <div className="tag">{item.state}</div>
                              </div>
                              <div className="foot">
                                <div className="postname">
                                  {item.occupation}
                                </div>
                                <div className="address">{item.cities}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )) ||
                      (cur == "companyGetEnterpriseSearchJob" && (
                        <div className="search-list">
                          {list.map((item, index) => (
                            <div
                              className={`search-list-item`}
                              key={index}
                              onClick={() => {
                                window.location.href =
                                  "https://slzhitui.com/biz/home";
                              }}
                            >
                              <div className="head">
                                <div className="name">
                                  <div className="title">{item.title}</div>
                                  <div className="subtitle">
                                    [{item.occupation_name}]
                                  </div>
                                </div>
                                <div className="price">
                                  {(item.min_salary_name ===
                                    item.max_salary_name &&
                                    item.max_salary_name) ||
                                    `${item.min_salary_name} ~ ${item.max_salary_name}`}
                                </div>
                              </div>
                              <div className="cont">
                                <div className="tag">{item.education_name}</div>
                                <div className="tag">
                                  {item.experience_name}
                                </div>
                                <div className="tag">
                                  {(item.sex == 0 && "不限") ||
                                    (item.sex == 1 && "男") ||
                                    (item.sex == 2 && "女") ||
                                    ""}
                                </div>
                                <div className="tag">
                                  {(item.min_age === item.max_age &&
                                    item.max_age) ||
                                    `${item.min_age} ~ ${item.max_age}`}
                                </div>
                                {/* <div className="tag">{item.state}</div> */}
                              </div>
                              <div className="foot">
                                {/* <div className="postname">{item.name}</div> */}
                                <div className="address">
                                  {item.work_provinces_name}-
                                  {item.work_cities_name}-{item.work_areas_name}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )) ||
                      ""}
                  </div>
                )) || (
                  <div className="spin">
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 60,
                          }}
                          spin
                        />
                      }
                      spinning={loading}
                    />
                  </div>
                )}
              </div>

              <div className="start mt-1">
                <div className="title">面相优秀企业的高效招聘服务</div>
                <div className="btn-cont">
                  <Button href="https://slzhitui.com/entrance/biz/register">
                    立即注册
                  </Button>
                </div>
              </div>

              <HomeFooter />
            </Content>
          </Layout>
        </Layout>
      </Spin>
    );
  }
}

export default Indexnew;
